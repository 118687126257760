export const CarrierLogo = {
    CDPS: 'images/carrier/canadapost.png',
    CLKRT: 'images/carrier/clarke.png',
    CNPR: 'images/carrier/canpar.png',
    DHLC: 'images/carrier/dhl_rgb.png',
    DICOM: 'images/carrier/dicom.png',
    DROS: 'images/carrier/dayandross.png',
    FDEG: 'images/carrier/fedex-en.png',
    LOOMIS: 'images/carrier/loomis.png',
    MRTO: 'images/carrier/maritimeontario.jpg',
    PRLA: 'images/carrier/purolator.png',
    PRLA2P9: 'images/carrier/purolator.png',
    PRLA2S9: 'images/carrier/purolator.png',
    PRLFR: 'images/carrier/purolator.png',
    RSDL: 'images/carrier/rosedale.png',
    UPSC: 'images/carrier/ups.png',
    USPS: 'images/carrier/usps.png',
    VANKF: 'images/carrier/vankam.png',
    NTNX: 'images/carrier/NATIONEX-Camarone.svg',
    DCMN: '/images/carrier/dicom.png',
    XPOC: '/images/carrier/xpo.svg',
    RDWY: '/images/carrier/yellow.svg',
    DAYR: '/images/carrier/dayandross.png',
	ACBH: '/images/carrier/arcbest.svg',
	PRRB: '/images/carrier/purolator.png',
	
	FXFR: '/images/carrier/FedEx_Freight.svg',
	OVLD: '/images/carrier/tst.png',
	POLT: '/images/carrier/polaris.png',
	MANI: '/images/carrier/manitoulin.png',
	
	FR_GLS: '/images/carrier/dicom.png',
	FR_DAYR: '/images/carrier/dayandross.png',
	FR_XPOC: '/images/carrier/xpo.svg',
	UBER: '/images/carrier/uber.svg'
}