import React, { useState} from 'react';
import { withRouter } from 'react-router';
import { Trans/*, useTranslation*/ } from 'react-i18next';
import {useHistory} from "react-router";
import i18next from "i18next";

function ShipmentNotFound() {

    const history = useHistory();
    const [paramState, setParamState] = useState();
    if (!paramState && history && history.location && history.location.state) {
        setParamState(history.location.state);
    }

    const onTryAgain = () => {
        history.push("/");
    }

    const onSignup = () => {
    	if (window.location.href.indexOf("paid") > -1) {
        	window.open('https://paid.com');
        } else if (window.location.href.indexOf("aiship") > -1) {
        	window.open('https://aiship.com');
        } else {
        	window.open('https://shiptime.com/?utm_source=shiptime&utm_medium=website&utm_campaign=track');
        }
    }

    return (
        <React.Fragment>
            <div className="row h-100">
                <div className="col-md-10 col-lg-9 col-xl-7 col-12 m-auto pb-4">
                    <div className="card mb-3 shipment-not-found text-dark">
                        <div className="card-body p-3">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-end pr-5">
                                       {window.location.href.indexOf("aiship") > -1 ? (
											<img src={'images/aiship-logo-dark.svg'} alt={"AIShip logo"} style={{ height: '55px' }} />
										) : window.location.href.indexOf("paid") > -1 ? (
											<img src={'images/paidshipping-color-logo.svg'} alt={"Paid logo"} style={{ height: '55px' }} />
										) : (
											<img
												src={i18next.language === 'fr' ? 'images/shiptime-logo-fr.svg' : 'images/shiptime-logo.svg'}
												alt={"ShipTime logo"} style={{ height: '90px' }} />
										)}
                                    </div>
                                </div>
                                <div className="row pl-4">
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1>
                                                    <Trans i18nKey="shipment.not.found">
                                                        Shipment not found
                                                    </Trans>
                                                </h1>
                                            </div>
                                            <div className="col-11 mt-3">
                                                <h4>
                                                	<Trans i18nKey="shipment.not.found.msg1">
                                                        Your shipment may not have been placed with 
                                                    </Trans>
                                                    {(window.location.href.indexOf("paid") > -1) ? "Paid" : "ShipTime"}
                                                	<span className="highlighted">
                                                		<Trans i18nKey="shipment.not.found.or">
                                                		 OR 
                                                		</Trans>
                                                	</span>
                                                	<Trans i18nKey="shipment.not.found.msg2">
                                                		 the ShipID/Tracking # 
                                            		</Trans>
                                                    <span className="highlighted">{(paramState && paramState.shipId) ? paramState.shipId : (paramState && paramState.trackingId) &&  paramState.trackingId}</span>
                                                    <Trans i18nKey="shipment.not.found.msg3">
                                                		 is incorrect. 
                                            		</Trans>
                                                 </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row flex-grow-1 pl-4">
                                    <div className="col-5 pr-5">
                                        <div className="row">
                                            <div className="col-12 mt-2">
                                                <button className="btn btn-tracking-theme" onClick={onTryAgain}>
                                                	<Trans i18nKey="shipment.not.found.try.again">
                                                		 Try Again 
                                            		</Trans>
                                                </button>
                                            </div>
                                            <div className="col-12 mt-5">
                                                <h2>
                                                	<Trans i18nKey="shipment.not.found.msg4">
                                                		 Don't know  
                                            		</Trans>
                                                	<span className="highlighted">
													  {window.location.href.indexOf("aiship") > -1 ? "AI Ship" : window.location.href.indexOf("paid") > -1 ? "Paid" : "ShipTime"}?
													</span>

                                                </h2>
                                            </div>
                                            <div className="col-12 mt-2">
                                                <button className="btn btn-tracking-theme" onClick={onSignup}>
                                                	<Trans i18nKey="shipment.not.found.signup">
                                                		 Signup 
                                            		</Trans>
                                            	</button>
                                            </div>
                                            <div className="col-11 mt-5">
                                                <h4>
                                                	<Trans i18nKey="shipment.not.found.msg5">
                                                		Save up to 
                                                	</Trans>
                                                	<span className="highlighted">76%</span>
                                                	<Trans i18nKey="shipment.not.found.msg6">
                                                		  on shipping plus an additional  
                                            		</Trans>
                                            		<span className="highlighted">20%</span>
                                        		 	<Trans i18nKey="shipment.not.found.msg7">
                                                		   on your first shipment.  
                                            		</Trans>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

}

export default withRouter(ShipmentNotFound);