import axios from 'axios';
export var API_BASE_URL = '';
/*
* Need to change while deploy on prod.
* export var API_BASE_URL = 'https://track.appspaces.ca';
*
* */

const headers = {
    'Content-Type': 'application/json',
}

export function track(shipId, trackingNo) {
    var params = '?';
    if (shipId && shipId !== '') {
        params = params + 'shipId=' + shipId
    }
    if (trackingNo && trackingNo !== '') {
        params = params + ((shipId && shipId !== '') ? '&' : '') + 'tracking=' + trackingNo
    }
    return axios.post(API_BASE_URL + "/track" + params, {}, {headers: headers});
}