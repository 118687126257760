import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { CarrierLogo } from "../utils/CarrierLogo";
import { Country } from "../utils/Country.js";
import { State } from "../utils/State";
import { track, API_BASE_URL } from "../utils/APIUtils";
import { getParams } from "../utils/common";
import Moment from "moment";
import TrackingInfo from "./TrackingInfo";
import HomePage from "./HomePage";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "./Loader";
import i18next from "i18next";

function TrackingPage(props) {
    const [state, setState] = useState(null);
    const { shipId, trackingNo } = getParams(props)
    const { t } = useTranslation();
    let data = {};
    const { history } = props;

    if (((!state || !Object.keys(state).length) && (shipId || trackingNo))) {
        track(shipId, trackingNo).then(response => {
            data = response.data;
            setState(data);
            if (data.error) {
                toast.error(t("shipment.not.found"), { position: "bottom-center", });
                history.push({pathname:'/not-found', state: { shipId: shipId, trackingId: trackingNo }});
            }
        }).catch(error => {
            toast.error(t("shipment.not.found"), { position: "bottom-center", });
            history.push({pathname:'/not-found', state: { shipId: shipId, trackingId: trackingNo }});
        });
    }

    const setData = (stateData) => {
        setState(stateData);
    }
    const numberRegex = /^[0-9\b]+$/;

    if ((shipId || trackingNo) && ((!state || !Object.keys(state).length))) {
        return (
            <Loader />
        );
    } else if ((!shipId && !trackingNo) || (state && state.error) || (shipId && !numberRegex.test(shipId))) {
        if ((shipId && !numberRegex.test(shipId))) {
            toast.error('Invalid ShipId', { position: "bottom-center", })
        }
        return (
            <React.Fragment>
                <HomePage setData={setData} />
                <ToastContainer />
            </React.Fragment>
        )
    } else if (state && Object.keys(state).length) {
        return <React.Fragment>
            {(state && state.brandedTrackingPage && state.companyInfo) ? state.customLogo ?
                <div className="row">
                    <div className="col-md-10 col-lg-9 col-xl-7 col-12 m-auto text-center d-flex justify-content-between">
                        {state && (state.logoRefrence ?
                            <div className="d-flex align-items-end" style={{ maxHeight: '140px', maxWidth: '25%' }}>
                            <img alt={"Company logo"} className="mb-3"
                                style={{ maxHeight: '100%', maxWidth: '100%' }}
                                src={API_BASE_URL + '/logo/' + state.logoRefrence} />
                                </div> : '')}
                        <div className={"d-flex flex-column align-items-end justify-content-center"} style={{'max-width': '25%'}}>
                            <span>Powered By</span>
                            {state && state.brandName && (state.brandName.toLowerCase() === 'aiship' ? (
								<a href='https://aiship.com' target={'_blank'} rel={"noopener noreferrer"}>
									<img alt={"AIShip logo"} className="mb-3"
										style={{ maxHeight: '140px', width: '200px', 'max-width': '100%' }}
										src={'images/aiship-logo-dark.svg'} />
								</a>
							) : state.brandName.toLowerCase() === 'paidshipping-ca' || state.brandName.toLowerCase() === 'paidshipping' ? (
								<a href='https://paid.com/shipping/' target={'_blank'} rel={"noopener noreferrer"}>
									<img alt={"PaidShipping logo"} className="mb-3"
										style={{ maxHeight: '140px', width: '200px', 'max-width': '100%' }}
										src={'images/paidshipping-color-logo.svg'} />
								</a>
							) : (
								<a href={i18next.language === 'fr' ? 'https://shiptime.com/fr/?utm_source=shiptime&utm_medium=website&utm_campaign=track' : 'https://shiptime.com/?utm_source=shiptime&utm_medium=website&utm_campaign=track'} target={'_blank'} rel={"noopener noreferrer"}>
									<img
										alt={"ShipTime logo"}
										className="mb-3"
										style={{ maxHeight: '140px', width: '200px', 'max-width': '100%' }}
										src={i18next.language === 'fr' ? 'images/shiptime-logo-fr.svg' : 'images/shiptime-logo.svg'}
									/>
								</a>
							))}
                        </div>
                    </div>
                </div>
                :
                <div className="row">
                    <div className="col-md-10 col-lg-9 col-xl-7 col-12 m-auto text-center d-flex justify-content-between">
                        {state && <h5 className={'mb-4'}>{state.accountName}</h5>}
                        <div className={"d-flex flex-column align-items-end justify-content-center"}>
                            <span>Powered By</span>
                            {state && state.brandName && (state.brandName.toLowerCase() === 'paidshipping-ca' || state.brandName.toLowerCase() === 'paidshipping') ?
                               <a href='https://paid.com/shipping/' target={'_blank'} rel={"noopener noreferrer"}>
                                <img alt={"PaidShipping logo"} className="mb-3"
                                     style={{ maxHeight: '140px', width: '200px', 'max-width': '100%' }}
                                     src={'images/paidshipping-color-logo.svg'} /></a>
                                :
                               <a href={i18next.language === 'fr' ? 'https://shiptime.com/fr/?utm_source=shiptime&utm_medium=website&utm_campaign=track' : 'https://shiptime.com/?utm_source=shiptime&utm_medium=website&utm_campaign=track'} target={'_blank'} rel={"noopener noreferrer"}>
                  			  <img
             		           alt={"ShipTime logo"}
               			         className="mb-3"
                     		   style={{ maxHeight: '140px', width: '200px', 'max-width': '100%' }}
                       			 src={i18next.language === 'fr' ? 'images/shiptime-logo-fr.svg' : 'images/shiptime-logo.svg'}
                   			 />
                		</a>}
                        </div>
                    </div>
                </div>
                :
                <div className="col-md-10 col-lg-9 col-xl-7 col-12 m-auto text-center">
                    {state && state.brandName && (state.brandName.toLowerCase() === 'paidshipping-ca' || state.brandName.toLowerCase() === 'paidshipping') ?
                        <a href='https://paid.com/shipping/' target={'_blank'} rel={"noopener noreferrer"}>
                        <img alt={"PaidShipping logo"} height={'400px'} width={'400px'} className="mb-3"
                            style={{ maxHeight: '140px', width: '200px', 'max-width': '100%' }}
                            src={'images/paidshipping-color-logo.svg'} /></a>
                        :
                           <a href={i18next.language === 'fr' ? 'https://shiptime.com/fr/?utm_source=shiptime&utm_medium=website&utm_campaign=track' : 'https://shiptime.com/?utm_source=shiptime&utm_medium=website&utm_campaign=track'} target={'_blank'} rel={"noopener noreferrer"}>
                  			  <img
             		           alt={"ShipTime logo"}
               			         className="mb-3"
                     		   style={{ maxHeight: '140px', width: '200px', 'max-width': '100%' }}
                       			 src={i18next.language === 'fr' ? 'images/shiptime-logo-fr.svg' : 'images/shiptime-logo.svg'}
                   			 />
                		</a>}
                </div>
            }
            <div className="row">
                <div className="col-md-10 col-lg-9 col-xl-7 col-12 m-auto">
                    <div className="card mb-3">
                        <div className="card-header px-4 border-bottom">
                            <div className="row">
                                <div className="col d-flex align-items-center">
                                    <img src={CarrierLogo[state.carrierCode]} height="30" alt={"Carrier logo"} />
                                </div>
                                <div className="col-auto d-flex align-items-center">
                                    {state && state.carrierTrackingURL && (!(state.currentStatus === 'CANCELLED' || state.currentStatus === 'REFUND_REQUESTED')) &&
                                        <a href={state.carrierTrackingURL} target={'_blank'} rel={"noopener noreferrer"}>
                                            <Trans i18nKey="track.shipment.on.carrier">
                                                Track your shipment on carrier
                                        </Trans>
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-4 pt-2 pb-4 mt-3">
                            <form className="_material-form-group-with-border needs-validation" noValidate>
                                <div className="row">
									{state && (state.carrierCode && state.carrierCode !== 'UBER') &&
	                                    <div className="col-4 form-group form-group-view">
	                                        <label className="view-label">
	                                            <Trans i18nKey="tracking.number">
	                                                Tracking Number
	                                            </Trans>
	                                        </label>
	                                        <div className="view--control">
	                                            {state && state.trackingId}
	                                        </div>
	                                    </div>
									}
                                    <div className="col-4 form-group form-group-view">
                                        <label className="view-label">
                                            <Trans i18nKey="shipment.id">
                                                Ship ID
                                            </Trans>
                                        </label>
                                        <div className="view--control">
                                            {state && state.shipmentId}
                                        </div>
                                    </div>
                                    <div className="col-4 form-group form-group-view">
                                        <label className="view-label">
                                            <Trans i18nKey="service">
                                                Service
                                            </Trans>
                                        </label>
                                        <div className="view--control">
                                            {state && state.serviceName}
                                        </div>
                                        <div className="view--control py-0">
                                            {state && state.carbonNeutralShipping &&
                                                <img alt={"Carbon Neutral Badge"} style={{maxWidth: '100%'}} height="25"
                                                    src={i18next.language === 'fr' ? 'images/carrier/carbon_neutral_fr.png' : 'images/carrier/carbon_neutral_en.png'} />}
                                        </div>
                                    </div>
                                    <div className="col-4 form-group form-group-view">
                                        <label className="view-label">
                                            <Trans i18nKey="origin">
                                                Origin
                                            </Trans>
                                        </label>
                                        <div className="view--control">
                                            {state && ((!state.origin.city || state.origin.city === "" ? "" : state.origin.city + ", ")
                                                + (!state.origin.state || state.origin.state === "" ? "" : State[state.origin.state] + ", ")
                                                + (!state.origin.country || state.origin.country === "" ? "" : Country[state.origin.country] + ", ")
                                                + (!state.origin.postalCode || state.origin.postalCode === "" ? "" : state.origin.postalCode))}
                                        </div>
                                    </div>
                                    <div className="col-4 form-group form-group-view">
                                        <label className="view-label">
                                            <Trans i18nKey="destination">
                                                Destination
                                            </Trans>
                                        </label>
                                        <div className="view--control">
                                            {state && ((!state.destination.city || state.destination.city === "" ? "" : state.destination.city + ", ")
                                                + (!state.destination.state || state.destination.state === "" ? "" : State[state.destination.state] + ", ")
                                                + (!state.destination.country || state.destination.country === "" ? "" : Country[state.destination.country] + ", ")
                                                + (!state.destination.postalCode || state.destination.postalCode === "" ? "" : state.destination.postalCode))}
                                        </div>
                                    </div>
                                    <div className="col-4 form-group form-group-view">
                                        <label className="view-label">
                                            <Trans i18nKey="shipment.date">
                                                Shipment Date
                                            </Trans>
                                        </label>
                                        <div
                                            className="view--control">{state && Moment(state.shipDate).format('DD-MMM-YYYY')}
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div>
								{(state.carrierCode && state.carrierCode === 'UBER' && !(state.currentStatus === 'DELIVERED' || state.currentStatus === 'CANCELLED' || state.currentStatus === 'REFUND_REQUESTED')) ?
								<div className="row">
									<iframe className="col-12 border-0 w-100" style={{height:'500px'}} src={state.carrierTrackingURL}/>
								</div>
								:
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col form-group form-group-view mb-2">
                                                <label className="view-label">
                                                    <Trans i18nKey="shipment.status">
                                                        Shipment Status
                                                    </Trans>
                                                </label>
                                                {state ?
                                                    <h5 className="view--control">
                                                        <Trans
                                                            i18nKey={`tracking.shipment.status.${state.currentStatus}`}>
                                                        </Trans>
                                                    </h5> : null
                                                }
                                            </div>
                                            <div className="col form-group form-group-view text-right mb-2">
                                                <label className="view-label">
                                                    <Trans i18nKey="estimated.delivery">
                                                        Estimated delivery:
                                                    </Trans>
                                                </label>
                                                <div
                                                    className="view--control">{(state && state.expectedDeliveryDate) ? Moment(state.expectedDeliveryDate).format('DD-MMM-YYYY') : '-'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-5">
                                        <div className={state && (state.currentStatus.toLowerCase() + " progress")}>
                                            {(() => {
                                                switch (state.currentStatus) {
                                                    case 'PENDING':
                                                        return (
                                                            <React.Fragment>
                                                                <div className="progress-bar filled"
                                                                    style={{ width: '33.33%' }}>
                                                                </div>
                                                                <div className="progress-bar" style={{ width: '33.33%' }}>
                                                                </div>
                                                                <div className="progress-bar" style={{ width: '33.33%' }}>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    case 'IN_TRANSIT':
                                                        return (
                                                            <React.Fragment>
                                                                <div className="progress-bar filled"
                                                                    style={{ width: '33.33%' }}>
                                                                </div>
                                                                <div className="progress-bar filled"
                                                                    style={{ width: '33.33%' }}>
                                                                </div>
                                                                <div className="progress-bar" style={{ width: '33.33%' }}>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    case 'DELIVERED':
                                                        return (
                                                            <React.Fragment>
                                                                <div className="progress-bar filled"
                                                                    style={{ width: '33.33%' }}>
                                                                </div>
                                                                <div className="progress-bar filled"
                                                                    style={{ width: '33.33%' }}>
                                                                </div>
                                                                <div className="progress-bar filled"
                                                                    style={{ width: '33.33%' }}>
                                                                </div>
                                                            </React.Fragment>
                                                        )

                                                    default:
                                                        return (
                                                            <React.Fragment>
                                                                <div className="progress-bar" style={{ width: '33.33%' }}>
                                                                </div>
                                                                <div className="progress-bar" style={{ width: '33.33%' }}>
                                                                </div>
                                                                <div className="progress-bar" style={{ width: '33.33%' }}>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                }
                                            })()}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {(!state || !state.history || !state.history.length) &&
                                            <div className={'text-center pb-4'}>
                                                <img className={'w-50'} src={"images/no-tracking-info.svg"}
                                                    alt={"No tracking info"} />
                                                <div style={{
                                                    'fontSize': '25px',
                                                    'fontWeight': 'bold',
                                                    'color': '#264a99'
                                                }}>No tracking information found!
                                            </div>
                                            </div>
                                        }
                                        {(state && state.history && state.history.length) &&
                                        	<div>
	                                            <ul className="checkpoints-list pl-md-5 p-0">
	                                                {state.history.map((trackDetails, index) => {
                                                        /*if ((trackDetails.name && trackDetails.name.toLowerCase() !== 'null'
                                                            && trackDetails.name.toLowerCase() !== 'n/a')
                                                            || (trackDetails.location && trackDetails.location.toLowerCase() !== 'n/a'
                                                            && trackDetails.location.toLowerCase() !== 'null'))*/
                                                            return <TrackingInfo key={index} trackingInfo={trackDetails} currentStatus={state.currentStatus}/>
	                                                })}
	                                            </ul>
	                                            <div style={{
	                                            'position': 'absolute',
	                                            'right': '15px',
	                                            'bottom': '5px',
	                                            'font-size': '20px',
	                                            'padding': '7px',
	                                            'background': '#ddeef3',
	                                            'height': '50px',
	                                            'width': '50px',
	                                            'text-align': 'center',
	                                            'border-radius': '50%',
	                                            'color': '#ffff',
	                                            'display': 'none',
	                                            'align-items': 'center',
	                                            'justify-content': 'center',
	                                            'box-shadow': '0px 0px 7px 3px #d8d8d8',
	                                            'cursor': 'pointer'}}>
											    	<img alt="Map" src="https://www.pngall.com/wp-content/uploads/2017/05/Map-Marker-PNG-Image.png" style={{'height': '35px'}} />
											    </div>
										    </div>
                                        }
                                    </div>
                                </div>
								}
                            </div>
                        </div>
                        {(state && state.brandedTrackingPage && state.socialInfo) ?
                            <div
                                className="card-footer d-flex align-items-center justify-content-center p-0 border-top">
                                {(state.facebookPage && state.facebookPage.toLowerCase() !== 'n/a') &&
                                    <a href={'https://www.facebook.com/' + state.facebookPage}
                                        className=" btn btn-shadow rounded mx-3 social-btn my-3"
                                        target={'_blank'} rel={"noopener noreferrer"}>
                                        <i className="fa fa-facebook" data-feather="facebook"></i>
                                    </a>
                                }
                                {(state.twitterPage && state.twitterPage.toLowerCase() !== 'n/a') &&
                                    <a href={'https://x.com/' + state.twitterPage}
                                        className=" btn btn-shadow rounded mx-3 social-btn my-3"
                                        target={'_blank'} rel={"noopener noreferrer"}>
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="12px" height="12px" clip-rule="evenodd">
											<polygon points="41,6 9.929,42 6.215,42 37.287,6"/>
											<polygon fill-rule="evenodd" points="31.143,41 7.82,7 16.777,7 40.1,41" clip-rule="evenodd"/>
											<path d="M15.724,9l20.578,30h-4.106L11.618,9H15.724 M17.304,6H5.922l24.694,36h11.382L17.304,6L17.304,6z"/>
										</svg>
                                    </a>
                                }
                                {(state.linkedInPage && state.linkedInPage.toLowerCase() !== 'n/a') &&
                                    <a href={'https://www.linkedin.com/' + state.linkedInPage}
                                        className=" btn btn-shadow rounded mx-3 social-btn my-3"
                                        target={'_blank'} rel={"noopener noreferrer"}>
                                        <i className="fa fa-linkedin" data-feather="linkedin"></i>
                                    </a>
                                }
                                {(state.instaPage && state.instaPage.toLowerCase() !== 'n/a') &&
                                    <a href={'https://www.instagram.com/' + state.instaPage}
                                        className=" btn btn-shadow rounded mx-3 social-btn my-3"
                                        target={'_blank'} rel={"noopener noreferrer"}>
                                        <i className="fa fa-instagram" data-feather="instagram"></i>
                                    </a>
                                }
                            </div>
                            :
                            (state.brandName.toLowerCase() === 'paidshipping-ca' || state.brandName.toLowerCase() === 'paidshipping') ?
                                <div
                                    className="card-footer d-flex align-items-center justify-content-center py-3 border-top">
                                    <a href={'https://www.facebook.com/paidinc'}
                                        className=" btn btn-shadow rounded mx-3 social-btn" target={'_blank'}
                                        rel={"noopener noreferrer"}>
                                        <i className="fa fa-facebook" data-feather="facebook"></i>
                                    </a>
                                    <a href={'https://x.com/Paid_inc'}
                                        className=" btn btn-shadow rounded mx-3 social-btn" target={'_blank'}
                                        rel={"noopener noreferrer"}>
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="12px" height="12px" clip-rule="evenodd">
											<polygon points="41,6 9.929,42 6.215,42 37.287,6"/>
											<polygon fill-rule="evenodd" points="31.143,41 7.82,7 16.777,7 40.1,41" clip-rule="evenodd"/>
											<path d="M15.724,9l20.578,30h-4.106L11.618,9H15.724 M17.304,6H5.922l24.694,36h11.382L17.304,6L17.304,6z"/>
										</svg>
                                    </a>
                                    <a href={'https://www.linkedin.com/company/paid-inc'}
                                        className=" btn btn-shadow rounded mx-3 social-btn" target={'_blank'}
                                        rel={"noopener noreferrer"}>
                                        <i className="fa fa-linkedin" data-feather="linkedin"></i>
                                    </a>
                                </div>
                                :
                                <div
                                    className="card-footer d-flex align-items-center justify-content-center py-3 border-top">
                                    <a href={'https://facebook.com/ShipTime'}
                                        className=" btn btn-shadow rounded mx-3 social-btn" target={'_blank'}
                                        rel={"noopener noreferrer"}>
                                        <i className="fa fa-facebook" data-feather="facebook"></i>
                                    </a>
                                    <a href={'https://x.com/ShipTime'}
                                        className=" btn btn-shadow rounded mx-3 social-btn" target={'_blank'}
                                        rel={"noopener noreferrer"}>
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="12px" height="12px" clip-rule="evenodd">
											<polygon points="41,6 9.929,42 6.215,42 37.287,6"/>
											<polygon fill-rule="evenodd" points="31.143,41 7.82,7 16.777,7 40.1,41" clip-rule="evenodd"/>
											<path d="M15.724,9l20.578,30h-4.106L11.618,9H15.724 M17.304,6H5.922l24.694,36h11.382L17.304,6L17.304,6z"/>
										</svg>
                                    </a>
                                    <a href={'http://linkedin.com/company/shiptime'}
                                        className=" btn btn-shadow rounded mx-3 social-btn" target={'_blank'}
                                        rel={"noopener noreferrer"}>
                                        <i className="fa fa-linkedin" data-feather="linkedin"></i>
                                    </a>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className="row pb-4">
                <div className="col-md-10 col-lg-9 col-xl-7 col-12 m-auto">
					{state && state.description !== null && (
						<div className="d-flex align-itemter justify-content-center">
							<p>{state.description}</p>
						</div>
					)}
                    <div className=" d-flex align-itemter justify-content-center">
                        {(state && state.brandedTrackingPage && state.socialInfo) ?
                            ((((state.website && state.website.toLowerCase() !== 'n/a') && state.website.toLowerCase() !== '')
                                || ((state.supportUrl && state.supportUrl.toLowerCase() !== 'n/a') && state.supportUrl.toLowerCase() !== ''))) &&
                            <div className="border btn mx-3 py-3 px-5 support-url"
                                style={{ borderRadius: "26px 26px 26px 26px" }}>
                                {
                                    (state.website && state.website.toLowerCase() !== 'n/a' && state.website.toLowerCase() !== '') &&
                                    <span className="my-3">
                                        <span>
                                            <Trans i18nKey="visit.us.here">
                                                Visit us here
                                            </Trans>
                                        </span>

                                        <a href={(state.website.includes('https:') || state.website.includes('http:')) ? state.website : ('http://' + state.website)}
                                            target={'_blank'} rel={"noopener noreferrer"}>
                                            <i className="fa fa-external-link px-2"></i>
                                        </a>
                                    </span>
                                }
                                {
                                    (state.supportUrl && state.supportUrl.toLowerCase() !== 'n/a' && state.supportUrl.toLowerCase() !== '') &&
                                    <span className="my-3">
                                        <a href={(state.supportUrl.includes('https:') || state.supportUrl.includes('http:')) ? state.supportUrl : 'http://' + state.supportUrl}
                                            target={'_blank'} rel={"noopener noreferrer"}>
                                            <span className="px-2">
                                                <Trans i18nKey="contact.our.support">
                                                    Contact our support
                                                </Trans>
                                            </span>
                                        </a>
                                        <i className="fa fa-question-circle"></i>
                                    </span>
                                }
                            </div>
                            :
                            <div className="border btn mx-3 py-3 px-5 support-url"
                                style={{ borderRadius: "26px 26px 26px 26px" }}>
                                {
                                    <span>
                                        <span>
                                            <Trans i18nKey="visit.us.here">
                                                Visit us here
                                            </Trans>
                                        </span>
                                        {(state.brandName.toLowerCase() === 'paidshipping-ca' || state.brandName.toLowerCase() === 'paidshipping') ?
                                            <a href={'https://paid.com'} target={'_blank'} rel={"noopener noreferrer"}>
                                                <i className="fa fa-external-link px-2"></i>
                                            </a>
                                            :
                                            <a href={'https://shiptime.com/?utm_source=shiptime&utm_medium=website&utm_campaign=track'} target={'_blank'}
                                                rel={"noopener noreferrer"}>
                                                <i className="fa fa-external-link px-2"></i>
                                            </a>
                                        }
                                    </span>
                                }
                                {
                                    (state.brandName.toLowerCase() === 'paidshipping-ca' || state.brandName.toLowerCase() === 'paidshipping') ?
                                        <span>
                                            <a href={'https://support.paid.com/en/support/home'} target={'_blank'}
                                                rel={"noopener noreferrer"}>
                                                <span className="px-2">
                                                    <Trans i18nKey="contact.our.support">
                                                        Contact our support
                                                    </Trans>
                                                </span>
                                            </a>
                                            <i className="fa fa-question-circle"></i>
                                        </span>
                                        :
                                        <span>
                                            <a href={'https://support.shiptime.com/en/support/home'} target={'_blank'}
                                                rel={"noopener noreferrer"}>
                                                <span className="px-2">
                                                    <Trans i18nKey="contact.our.support">
                                                        Contact our support
                                                    </Trans>
                                                </span>
                                            </a>
                                            <i className="fa fa-question-circle"></i>
                                        </span>
                                }
                            </div>
                        }
                    </div>
                    {state && state.brandName && state.brandedTrackingPage &&
                        <div className=" d-flex align-itemter justify-content-center py-3">
                            {(state.brandName.toLowerCase() === 'paidshipping-ca' || state.brandName.toLowerCase() === 'paidshipping') ?
                                <span><Trans i18nKey="powered.by">Powered By </Trans><a href="https://paid.com/shipping"
                                    target={'_blank'}
                                    rel={"noopener noreferrer"}>PaidShipping</a></span>
                                :
                                <span><Trans i18nKey="powered.by">Powered By </Trans><a href="https://shiptime.com/?utm_source=shiptime&utm_medium=website&utm_campaign=track"
                                    target={'_blank'}
                                    rel={"noopener noreferrer"}>ShipTime</a></span>
                            }
                        </div>
                    }

                </div>
            </div>
        </React.Fragment>
    } else {
        toast.error(t("shipment.not.found"), { position: "bottom-center", })
        history.push({pathname:'/not-found', state: { shipId: shipId, trackingId: trackingNo }});
        return;
    }

}

export default withRouter(TrackingPage);