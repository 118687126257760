export const ShipmentStatus = {
    DEFERRED : {name: 'Deferred', img: 'images/icon/checked.svg', gryImg: 'images/icon/checked.svg', color:'green'},
    PENDING : {name: 'Pending', img: 'images/icon/package.svg', gryImg: 'images/icon/checked.svg', color:'orange'},
    MANIFESTED : {name: 'Manifested', img: 'images/icon/checked.svg', gryImg: 'images/icon/checked.svg', color:'orange'},	// manifest got created
    IN_TRANSIT : {name: 'In Transit', img: 'images/icon/truck.svg', gryImg: 'images/icon/checked.svg', color:'blue'},
    DELIVERED : {name: 'Delivered', img: 'images/icon/checked.svg', gryImg: 'images/icon/checked.svg', color:'green'},
    REFUND_REQUESTED : {name: 'Refund Requested', img: 'images/icon/checked.svg', gryImg: 'images/icon/checked.svg', color:'green'},	// Refund requested
    CANCELLED : {name: 'Cancelled', img: 'images/icon/warning.svg', gryImg: 'images/icon/checked.svg', color:'red'},
    EXCEPTION : {name: 'Exception', img: 'images/icon/warning.svg', gryImg: 'images/icon/checked.svg', color:'red'},
    API2CART_PENDING : {name: 'Api2cart Pending', img: 'images/icon/checked.svg', gryImg: 'images/icon/checked.svg', color:'red'} // non-shiptime status, strictly for api2cart process of new orders
}