import React from 'react';

function Loader() {
    return (
        <div className="loader">
            <div className="text-center"><span className="showloader"></span>
                <div className="loader-text">LOADING</div>
            </div>
        </div>
    );
}

export default Loader;