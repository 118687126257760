import React, {useState} from 'react';
import './App.css';
import i18n from "./i18n";
import objectPath from "object-path";
import TrackingPage from "./components/main/TrackingPage";
import {Route, BrowserRouter as Router} from 'react-router-dom';
import {getParams} from "./components/utils/common";
import 'react-toastify/dist/ReactToastify.css';
import HomePage from "./components/main/HomePage";
import {withRouter, Switch} from "react-router";
import ShipmentNotFound from "./components/main/ShipmentNotFound";

function App(props) {

    const params = getParams(props);
    let local = objectPath.get(params, "locale", 'en');
    //let shipId = objectPath.get(params, "shipId", '');

    let lang = (local.toLowerCase() === 'fr') ? 'fr' : 'en';
    const [langState, setLangState] = useState({lang: lang});

    const favicon = document.getElementById("favicon");
    if (window.location.href.indexOf("paid") > -1) {
        favicon.href = "paid-favicon.ico";
    } else {
        favicon.href = "shiptime-favicon.ico";
    }
                
    const changeLang = (lang) => {
        setLangState({lang: lang});
        i18n.changeLanguage(langState.lang);
    }
    
	
    i18n.changeLanguage(langState.lang);
    return (
        <div className={`_mainContainer py-4 h-100`}>
            <Router>
                <Switch>
                	<Route path="/not-found">
                        <ShipmentNotFound />
                    </Route>
                    <Route path="/search" exact>
                        <TrackingPage />
                    </Route>
                    
                    <Route path="/">
                        <HomePage />
                    </Route>
                </Switch>
            </Router>
            <div className={"language-container"}>
                {
                    langState.lang === 'en'
                        ?
                        <span className={"language-btn"} onClick={() => changeLang('fr')}>
                            <i className="fa fa-language mr-2" aria-hidden="true"></i>Français
                        </span>
                        :
                        <span className={"language-btn"} onClick={() => changeLang('en')}>
                            <i className="fa fa-language mr-2" aria-hidden="true"></i>English
                        </span>
                }
            </div>
        </div>
    );
}

export default withRouter(App);
