export const Country = {
    CA: "CANADA",
    US: "UNITED STATES",
    AD: "ANDORRA",
    AE: "UNITED ARAB EMIRATES",
    AF: "AFGHANISTAN",
    AG: "ANTIGUA AND BARBUDA",
    AI: "ANGUILLA",
    AL: "ALBANIA",
    AM: "ARMENIA",
    AN: "NETHERLANDS ANTILLES",
    AO: "ANGOLA",
    AR: "ARGENTINA",
    AS: "AMERICAN SAMOA",
    AT: "AUSTRIA",
    AU: "AUSTRALIA",
    AW: "ARUBA",
    AZ: "AZERBAIJAN",
    BA: "BOSNIA AND HERZEGOVINA",
    BB: "BARBADOS",
    BD: "BANGLADESH",
    BE: "BELGIUM",
    BF: "BURKINA FASO",
    BG: "BULGARIA",
    BH: "BAHRAIN",
    BI: "BURUNDI",
    BJ: "BENIN",
    BM: "BERMUDA",
    BN: "BRUNEI DARUSSALAM",
    BO: "BOLIVIA",
    BR: "BRAZIL",
    BS: "BAHAMAS",
    BT: "BHUTAN",
    BW: "BOTSWANA",
    BY: "BELARUS",
    BZ: "BELIZE",
    CC: "COCOS (KEELING) ISLANDS",
    CD: "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
    CF: "CENTRAL AFRICAN REPUBLIC",
    CG: "CONGO",
    CH: "SWITZERLAND",
    CI: "COTE D'IVOIRE",
    CK: "COOK ISLANDS",
    CL: "CHILE",
    CM: "CAMEROON",
    CN: "CHINA",
    CO: "COLOMBIA",
    CR: "COSTA RICA",
    CU: "CUBA",
    CV: "CAPE VERDE",
    CY: "CYPRUS",
    CZ: "CZECH REPUBLIC",
    DE: "GERMANY",
    DJ: "DJIBOUTI",
    DK: "DENMARK",
    DM: "DOMINICA",
    DO: "DOMINICAN REPUBLIC",
    DZ: "ALGERIA",
    EC: "ECUADOR",
    EE: "ESTONIA",
    EG: "EGYPT",
    ER: "ERITREA",
    ES: "SPAIN",
    ET: "ETHIOPIA",
    FI: "FINLAND",
    FJ: "FIJI",
    FK: "FALKLAND ISLANDS (MALVINAS)",
    FM: "MICRONESIA, FEDERATED STATES OF",
    FO: "FAROE ISLANDS",
    FR: "FRANCE",
    GA: "GABON",
    GB: "UNITED KINGDOM",
    GD: "GRENADA",
    GE: "GEORGIA",
    GF: "FRENCH GUIANA",
    GH: "GHANA",
    GI: "GIBRALTAR",
    GL: "GREENLAND",
    GM: "GAMBIA",
    GN: "GUINEA",
    GP: "GUADELOUPE",
    GQ: "EQUATORIAL GUINEA",
    GR: "GREECE",
    GG: "GUERNSEY",
    GT: "GUATEMALA",
    GU: "GUAM",
    GW: "GUINEA-BISSAU",
    GY: "GUYANA",
    HK: "HONG KONG",
    HN: "HONDURAS",
    HR: "CROATIA",
    HT: "HAITI",
    HU: "HUNGARY",
    IC: "CANARY ISLANDS",
    ID: "INDONESIA",
    IE: "IRELAND",
    IL: "ISRAEL",
    IN: "INDIA",
    IQ: "IRAQ",
    IR: "IRAN, ISLAMIC REPUBLIC OF",
    IS: "ICELAND",
    IT: "ITALY",
    JE: "JERSEY",
    JM: "JAMAICA",
    JO: "JORDAN",
    JP: "JAPAN",
    KE: "KENYA",
    KG: "KYRGYZSTAN",
    KH: "CAMBODIA",
    KI: "KIRIBATI",
    KM: "COMOROS",
    KN: "SAINT KITTS",
    KP: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
    KR: "KOREA, REPUBLIC OF",
    KV: "KOSOVO",
    KW: "KUWAIT",
    KY: "CAYMAN ISLANDS",
    KZ: "KAZAKHSTAN",
    LA: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    LB: "LEBANON",
    LC: "SAINT LUCIA",
    LI: "LIECHTENSTEIN",
    LK: "SRI LANKA",
    LR: "LIBERIA",
    LS: "LESOTHO",
    LT: "LITHUANIA",
    LU: "LUXEMBOURG",
    LV: "LATVIA",
    LY: "LIBYAN ARAB JAMABIRIYA",
    MA: "MOROCCO",
    MC: "MONACO",
    MD: "MOLDOVA, REPUBLIC OF",
    MG: "MADAGASCAR",
    MH: "MARSHALL ISLANDS",
    MK: "MACEDONIA, THE FORMER YUGOSLAV REPU8LIC OF",
    ML: "MALI",
    MM: "MYANMAR",
    MN: "MONGOLIA",
    MO: "MACAU",
    MP: "NORTHERN MARIANA ISLANDS",
    MQ: "MARTINIQUE",
    MR: "MAURITANIA",
    MS: "MONTSERRAT",
    MT: "MALTA",
    MU: "MAURITIUS",
    MV: "MALDIVES",
    MW: "MALAWI",
    MX: "MEXICO",
    MY: "MALAYSIA",
    MZ: "MOZAMBIQUE",
    NA: "NAMIBIA",
    NC: "NEW CALEDONIA",
    NE: "NIGER",
    NF: "NORFOLK ISLAND",
    NG: "NIGERIA",
    NI: "NICARAGUA",
    NL: "NETHERLANDS",
    NO: "NORWAY",
    NP: "NEPAL",
    NU: "NIUE",
    NZ: "NEW ZEALAND",
    OM: "OMAN",
    PA: "PANAMA",
    PE: "PERU",
    PF: "FRENCH POLYNESIA",
    PG: "PAPUA NEW GUINEA",
    PH: "PHILIPPINES",
    PK: "PAKISTAN",
    PL: "POLAND",
    PR: "PUERTO RICO",
    PT: "PORTUGAL",
    PW: "PALAU",
    PY: "PARAGUAY",
    QA: "QATAR",
    RE: "REUNION",
    RO: "ROMANIA",
    RS: "SERBIA",
    RU: "RUSSIAN FEDERATION",
    RW: "RWANDA",
    SA: "SAUDI ARABIA",
    SB: "SOLOMON ISLANDS",
    SC: "SEYCHELLES",
    SD: "SUDAN",
    SE: "SWEDEN",
    SG: "SINGAPORE",
    SH: "SAINT HELENA",
    SI: "SLOVENIA",
    SK: "SLOVAKIA",
    SL: "SIERRA LEONE",
    SM: "SAN MARINO",
    SN: "SENEGAL",
    SO: "SOMALIA",
    SR: "SURINAME",
    SS: "SOUTH SUDAN",
    ST: "SAO TOME AND PRINCIPE",
    SV: "EL SALVADOR",
    SY: "SYRIAN ARAB REPUBLIC",
    SZ: "SWAZILAND",
    TC: "TURKS AND CAICOS ISLANDS",
    TD: "CHAD",
    TG: "TOGO",
    TH: "THAILAND",
    TJ: "TAJIKISTAN",
    TL: "EAST TIMOR",
    TM: "TURKMENISTAN",
    TN: "TUNISIA",
    TO: "TONGA",
    TR: "TURKEY",
    TT: "TRINIDAD AND TOBAGO",
    TV: "TUVALU",
    TW: "TAIWAN, PROVINCE OF CHINA",
    TZ: "TANZANIA, UNITED REPUBLIC OF",
    UA: "UKRAINE",
    UG: "UGANDA",
    UY: "URUGUAY",
    UZ: "UZBEKISTAN",
    VE: "VENEZUELA",
    VG1: "VIRGIN ISLANDS, BRITISH",
    VI3: "VIRGIN ISLANDS, U.S.",
    VN: "VIET NAM",
    VU: "VANUATU",
    WF: "WALLIS AND FUTUNA",
    WS: "SAMOA",
    XN: "NEVIS",
    XS: "SOMALILAND, REP OF (NORTH SOMALIA)",
    YE: "YEMEN",
    YT: "MAYOTTE",
    YU: "YUGOSLAVIA",
    ZA: "SOUTH AFRICA",
    ZM: "ZAMBIA",
    ZW: "ZIMBABWE",
    AG1: "Antigua",
    AG2: "Barbuda",
    AN1: "Bonaire",
    AN2: "Curacao",
    CS: "Channel Islands",
    ME: "Montenegro",
    NR: "Nauru",
    AN3: "Netherlands Antilles",
    AN4: "Saba",
    GPE: "St. Barthelemy",
    VI1: "St. Croix",
    AN5: "St. Eustatius",
    AN6: "St. Martin",
    VI2: "St. Thomas",
    VC: "St. Vincent",
    VG2: "Tortola",
    VA: "Vatican City"
}