import React from 'react';
import { useTranslation } from 'react-i18next';
import {ShipmentStatus} from "../utils/ShipmentStatus";
const momentTz = require('moment-timezone');

function TrackingInfo(props) {

	momentTz.tz.setDefault('America/New_York');
    const trackingInfo = props.trackingInfo;
	const dateTime = momentTz(trackingInfo.timestamp);
	const currentStatus = props.currentStatus;
	const { t } = useTranslation();
	
	const formattedDate = dateTime.format('LL');
    const formattedTime = dateTime.format('LT'); // Format to display only hours and minutes
	
	//const formattedDate = dateTime.format('YYYY-MM-DD');
    //const formattedTime = dateTime.format('HH:mm'); // Format to display only hours and minutes

    return <React.Fragment>
        <li className="columns checkpoint-list-item">
            <div className="column text-right">
                <time>{formattedDate}<br/>
                    <small>{formattedTime}</small>
                </time>
            </div>
            <div className="column is-narrow">
                <a className={ShipmentStatus[trackingInfo.status].color + " status"} style={{'pointer-events': 'none'}} href="javascript(0);">
                    <img className="tracking-icon"
                         src={ShipmentStatus[trackingInfo.status].img}
                         alt="status-delivered"/>
                </a>
            </div>
            <div className="column">
                <div className="has-text-left">
                    {trackingInfo.name && trackingInfo.name.toLowerCase() !== 'null' && trackingInfo.name.toLowerCase() !== 'n/a' ? trackingInfo.name : (trackingInfo.status === 'PENDING' ? t("tracking.shipment.recordCreated") : '')}
                    <br/>
                    <small>{trackingInfo.location && trackingInfo.location.toLowerCase() !== 'n/a' && trackingInfo.location.toLowerCase() !== 'null'
								? trackingInfo.location 
								: ''}</small>
                </div>
            </div>
        </li>
    </React.Fragment>

}

export default TrackingInfo;